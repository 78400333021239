import { dialogMgr } from 'widgets/toolbox/dialogMgr';
import { timeout } from 'widgets/toolbox/util';
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import {
    appendParamsToUrl,
    getUrlParams,
    removeParamFromURL
} from 'widgets/toolbox/util';

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32,
    TAB: 9,
    DOWN: 40,
    UP: 38,
    RIGHT: 39,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36
});

/**
 * @typedef {ReturnType <typeof import('widgets/global/ListAccessibility').default>} ListAccessibility
 * @typedef {InstanceType <typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @description Base RefinementsPanel implementation
 * @param {ListAccessibility} ListAccessibility Base widget for extending
 * @returns {typeof RefinementsPanel} Refinements Panel class
 */
export default function (ListAccessibility) {
    /**
     * @class RefinementsPanel
     * @augments ListAccessibility
     * @classdesc Represents RefinementsPanel component with next features:
     * 1. Updating product grid using product refinement, sorting and paging
     * 2. Support keyboard navigation for accessibility
     * 3. Control submenu
     *
     * Should contain {@link RefinementMenuItem}
     * @property {string} data-widget - Widget name "refinementsPanel"
     * @property {string} data-event-keydown - keydown handler
     * @property {string} data-show-ajax-url - URL for AJAX update
     * @property {string} [data-classes-backdrop-active=m-active] - class added/removed on 'self' element on openPanel/closePanel
     * @property {string} [data-classes-dialog-open=m-opened] - class added/removed on ref element 'dialog' on openPanel/closePanel
     * @property {string} [data-classes-active-level=m-active_level_] - prefix for class that is added/removed from 'panel' ref element when navigating between panels
     * @property {string} [data-item-switch-timeout=500] - timeout (in ms) between panels focus changes
     * @category widgets
     * @subcategory search
     * @example
     *<aside
     *    class="l-plp-refinements_slide b-refinements_slide_panel ${!searchHasProducts ? 'm-no_results' : ''}"
     *    data-widget="refinementsPanel"
     *    data-event-keydown="handleKeydown"
     *    data-forward-to-parent="updateView"
     *    data-widget-event-noresult="handleNoResult"
     *    data-show-ajax-url="${URLUtils.url('Search-ShowAjax')}"
     *    data-panel-container="panel"
     *    data-drag-direction-x="right"
     *    id="refinements-panel"
     *    aria-labelledby="refinements-panel-title-xl"
     *    data-tau="refinements_menu_panel"
     *>
     *</aside>
     */
    class RefinementsPanel extends ListAccessibility {
        prefs() {
            return {
                classesBackdropActive: 'm-active',
                classesDialogOpen: 'm-opened',
                classesActiveLevel: 'm-active_level_',
                itemSwitchTimeout: 500,
                showAjaxUrl: '',
                ...super.prefs()
            };
        }

        /**
         * @description Widget logic initialization
         * @returns {void}
         */
        init() {
            super.init();
            this.eventBus().on('refinement.panel.open', 'openPanel');
            this.eventBus().on('refinement.panel.previous.level', 'previousLevel');
            this.eventBus().on('refinement.panel.next.level', 'nextLevel');
            this.eventBus().on('refinement.panel.update', 'updatePanel');
        }

        /**
         * @description Update Panel
         * @param {object} data Update data
         * @returns {void}
         */
        updatePanel(data) {
            this.ref('dialog').attr('aria-busy', 'true');
            this.ref('panel').removeClass(this.prefs().classesActiveLevel + 2).addClass(this.prefs().classesActiveLevel + 1);

            // apply filters and results count only after panel is change back
            this.onDestroy(timeout(() => {
                getJSONByUrl(data.url).then(res => {
                    const param = getUrlParams(data.url);

                    if (Object.keys(res).length && res.count) {
                        this.render(
                            'template',
                            {
                                ...res,
                                // @ts-ignore
                                updateUrl: removeParamFromURL(appendParamsToUrl(this.prefs().showAjaxUrl, param), 'ajax')
                            },
                            this.ref('dialog')
                        ).then(() => {
                            this.ref('dialog').addClass(this.prefs().classesDialogOpen);
                            this.ref('dialog').attr('aria-busy', 'false');
                            this.defineItems();
                            this.setFocusToFirstItem();
                        });
                    } else {
                        this.emit('noresult');
                    }
                });
            }, this.prefs().itemSwitchTimeout));
        }

        /**
         * @description Select next level
         * @param {object} data data
         * @returns {void}
         */
        nextLevel(data) {
            this.ref('dialog').attr('aria-busy', 'true');

            this.getById('subpanel', (subpanel) => {
                if (data.panelName) {
                    subpanel.setSubmenuTitle(data.panelName);
                }

                if (data.refinementId) {
                    subpanel.setSubmenuId(data.refinementId);
                }

                if (data.htmlMarkup) {
                    subpanel.setSubmenuHTML(data.htmlMarkup);
                }

                subpanel.show();
            });

            this.ref('panel').removeClass(this.prefs().classesActiveLevel + 1).addClass(this.prefs().classesActiveLevel + 2);
            this.onDestroy(timeout(() => {
                this.ref('dialog').attr('aria-busy', 'false');
                this.getById('subpanel', (subpanel) => {
                    if (data.showRefinementControls) {
                        subpanel.showRefinementControls(true);
                    } else {
                        subpanel.hideRefinementControls();
                    }

                    subpanel.markSubmenuOpened();
                    subpanel.defineItems();
                    subpanel.setFocusToFirstItem();
                    subpanel.parseUrlRefinementParameter();
                });
            }, this.prefs().itemSwitchTimeout));
        }

        /**
         * @description Select previous level
         * @returns {void}
         */
        previousLevel() {
            this.ref('panel').removeClass(this.prefs().classesActiveLevel + 2);
            this.ref('panel').addClass(this.prefs().classesActiveLevel + 1);
            this.onDestroy(timeout(() => {
                this.setFocusToCurrentItem();
            }, this.prefs().itemSwitchTimeout));
        }

        /**
         * @description Open Menu Panel
         * @param {object} data data
         * @returns {void}
         */
        openPanel(data) {
            this.ref('dialog').attr('aria-busy', 'true');
            this.getRefinementsModel();

            this.render('template', this.model, this.ref('dialog'))
                .then(() => {
                    this.ref('self').addClass(this.prefs().classesBackdropActive);
                    this.ref('dialog').addClass(this.prefs().classesDialogOpen);

                    this.onDestroy(timeout(() => {
                        this.defineItems();
                        this.ref('dialog').attr('aria-busy', 'false');

                        if (data.focusToLastItem) {
                            this.setFocusToLastItem();
                        } else {
                            this.setFocusToFirstItem();
                        }
                    }, this.prefs().itemSwitchTimeout));

                    // @ts-ignore
                    dialogMgr.openDialog(this);
                    this.ref('dialog').attr('role', false);
                    this.ref('dialog').attr('aria-modal', false);
                });
        }

        /**
         * @description Close Panel
         * @returns {void}
         */
        closePanel() {
            this.ref('self').removeClass(this.prefs().classesBackdropActive);
            this.ref('dialog').removeClass(this.prefs().classesDialogOpen);

            this.eventBus().emit('refinement.panel.close');

            dialogMgr.closeDialog();
        }

        /**
         * @description Get Refinements Model
         * @returns {void}
         */
        getRefinementsModel() {
            var refinementsModel = this.ref('refinementsModel').getText().trim() || '';
            try {
                refinementsModel = JSON.parse(`"${refinementsModel}"`);
                this.model = JSON.parse(refinementsModel);
            } catch (e) {
                this.model = {};
            }
        }

        /**
         * @description Close On Backdrop handler
         * @listens dom#click
         * @param {RefElement} ref - interacted element
         * @param {Event} event - event object
         * @returns {void}
         */
        closeOnBackdrop(ref, event) {
            if (event.target === this.ref('self').get()) {
                this.closePanel();
            }
        }

        /**
         * @description Cancel Handler
         * @returns {void}
         */
        cancel() {
            this.closePanel();
        }

        /**
         * @description Refresh Handler
         * @returns {void}
         */
        onRefresh() {
            super.onRefresh();
            dialogMgr.closeDialog();
        }

        /**
         * @description Keydown Event handler
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.PAGEUP:
                case keyCode.HOME:
                    this.setFocusToFirstItem();
                    preventEventActions = true;

                    break;

                case keyCode.PAGEDOWN:
                case keyCode.END:
                    this.setFocusToLastItem();
                    preventEventActions = true;

                    break;

                case keyCode.TAB:
                    this.closePanel();

                    break;

                case keyCode.DOWN:
                    this.setFocusToNextItem();
                    preventEventActions = true;

                    break;

                case keyCode.UP:
                    this.setFocusToPreviousItem();
                    preventEventActions = true;

                    break;

                case keyCode.RIGHT:
                    if ('openMenu' in this.currentItem) {
                        this.currentItem.openMenu();
                        preventEventActions = true;
                    }

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return RefinementsPanel;
}
