/**
 * @typedef {ReturnType<typeof import('widgets/forms/BasicForm').default>} BasicForm
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Base NoSearchResultsForm implementation
 * <br>This is simplified form with no validation rules applied
 * <br>Has an ability to clear user input bu pressing button
 * @param {BasicForm} BasicForm Base widget for extending
 * @returns {typeof NoSearchResultsForm} NoSearchResultsForm class
 */
export default function (BasicForm) {
    /**
     * @class NoSearchResultsForm
     * @augments BasicForm
     * @classdesc NoSearchResultsForm widget implementation
     * @property {string} data-widget - Widget name "noSearchResultsForm"
     * @property {string} data-classes-clear-enabled - class to toggle on clear input button
     * @example
     * // use this code to display widget
     * <form
     *     action="${URLUtils.url('Search-Show')}"
     *     method="GET"
     *     data-widget="noSearchResultsForm"
     *     data-event-submit="handleSubmit"
     * >
     *     ... form contents
     *     <input data-ref="input" role="combobox" name="q" value="" data-event-input="handleInput" />
     *     <button data-ref="clearButton" data-event-click="clearInput"
     *         type="reset"
     *         title="${Resource.msg('header.search.clear', 'search', null)}"
     *         tabindex="-1"
     *     >
     *         <isinclude template="/common/svg/searchClear" />
     *     </button>
     *     ... submit button
     * </form>
     */
    class NoSearchResultsForm extends BasicForm {
        prefs() {
            return {
                classesClearEnabled: 'm-visible',
                ...super.prefs()
            };
        }

        /**
         * @description Submit form simplified handler
         * @param {refElement} _el event source element
         * @param {Event} event event instance
         */
        handleSubmit(_el, event) {
            if (!this.ref('input').val()) {
                event.preventDefault();
            }
        }

        handleInput() {
            this.toggleClearButton(this.ref('input').val());
        }

        toggleClearButton(isInputHasValue) {
            this.ref('clearButton').toggleClass(this.prefs().classesClearEnabled, isInputHasValue);
        }

        clearInput() {
            const searchInput = this.ref('input');
            searchInput.val('');

            const searchInputField = searchInput.get();
            if (searchInputField) {
                searchInputField.focus();
            }

            this.toggleClearButton(false);
        }
    }

    return NoSearchResultsForm;
}
