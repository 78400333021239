const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32
});

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Base Refinement implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof Refinement} RefinementMenuItem widget
 */
export default function (Widget) {
    /**
     * @class Refinement
     * @augments Widget
     * @classdesc Rifinement widget, which could be used in refinement panel
     * Represents Refinement component with next features:
     * 1. Support keyboard navigation for accessibility
     * 2. Support all typo of refinement
     * 3. Use "update" and "uncheck" event to handle changes used parent methods
     * @property {string} data-widget - Widget name `refinement`
     * @property {string} data-widget-event-click - Event listener to call Parent's widget method
     * @property {string} data-event-click - Event listener method `handleClick` for click event on widget
     * @property {string} data-attr-id - attribute id
     * @property {string} data-attr-value - attribute value for on price refinement
     * @property {string} data-value-from - from value for price refinement
     * @property {string} data-value-to - to value for price refinement
     * @property {string} data-event-keydown - keydown handler
     * @property {string} data-widget-event-update="updateRefinementControls"
     * @property {string} data-widget-event-uncheck="uncheckRefinements"
     * @property {string} data-event-click - click handler
     * @property {string} data-checked - checked flag, used for initialization
     * @property {string} [data-prevent-action=false] - prevent any action flag
     * @category widgets
     * @subcategory search
     * @example
     * <div
     *     class="b-refinement_link"
     *     role="menuitem"
     *     aria-checked="false"
     *     aria-disabled="false"
     *     data-widget="refinement"
     *     data-href="{{url}}"
     *     data-event-keydown.prevent="handleKeydown"
     *     data-widget-event-update="updateView"
     *     data-event-click.prevent="changeState"
     *     data-prevent-action="{{${'#'}selected}}true{{/selected}}{{^selected}}false{{/selected}}"
     *     title="{{${'#'}selected}}${Resource.msg('msg.assistive.selected.text', 'common', null)}{{/selected}} {{title}}"
     *     tabindex="0"
     *     {{${'#'}selected}}
     *         data-tau="refinements_option_selected"
     *     {{/selected}}
     * >
     *     <span data-tau="refinements_option_name">
     *         {{displayValue}} {{${'#'}hitCount}}({{hitCount}}){{/hitCount}}
     *     </span>
     * </div>
     */
    class Refinement extends Widget {
        /**
         * @description Preferences
         * @returns {object} Preferences object
         */
        prefs() {
            return {
                attrRefinement: 'data-refinement',
                preventAction: false,
                ...super.prefs()
            };
        }

        /**
         * @description Toggle check status
         * @returns {void}
         */
        toggleCheck() {
            if (this.selected) {
                this.uncheck();
            } else {
                this.check();
            }
        }

        /**
         * @description Check
         * @returns {void}
         */
        check() {
            this.ref('self').attr('aria-checked', 'true');
            this.selected = true;
        }

        /**
         * @description Uncheck
         * @returns {void}
         */
        uncheck() {
            this.ref('self').attr('aria-checked', 'false');
            this.selected = false;
        }

        /**
         * @description Widget logic initialization
         * @returns {void}
         */
        init() {
            this.selected = this.prefs().checked;
            this.attrId = this.prefs().attrId;
            this.value = this.prefs().attrValue;
            this.min = this.prefs().valueFrom;
            this.max = this.prefs().valueTo;
        }

        /**
         * @description Focus
         * @returns {void}
         */
        focus() {
            return this.ref('self').focus();
        }

        /**
         * @description Change state
         * @param {boolean} [skipCurrentItemDetermination] Skip Current Item Determination
         * @returns {void}
         */
        changeState(skipCurrentItemDetermination) {
            const param = {};

            if (this.prefs().preventAction) {
                return;
            }

            if (this.attrId === 'price') {
                this.emit('uncheck');
                this.check();
            } else {
                this.toggleCheck();
            }

            param.skipCurrentItemDetermination = skipCurrentItemDetermination;

            this.emit('update', param);
        }

        /**
         * @description Is Attribute Refinement
         * @returns {boolean} Attribute refinement flag
         */
        isAttributeRefinement() {
            return this.ref('self').hasAttr(this.prefs().attrRefinement);
        }

        /**
         * @description Handle Click
         */
        handleClick() {
            this.changeState(true);
        }

        /**
         * @description Keydown Event handler
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.RETURN:
                case keyCode.SPACE:
                    this.changeState();
                    preventEventActions = true;
                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return Refinement;
}
