import { get } from 'widgets/toolbox/util';

const keyCode = Object.freeze({
    RETURN: 13
});

// TODO: JSDoc for all methods
// TODO: implement accordion https://www.w3.org/TR/wai-aria-practices/#accordion
// or https://www.w3.org/TR/wai-aria-practices/#disclosure should be implemented on filter sections.
// PLP - some filters expanded, some collapsed
// TODO: notify blind user about all not/applied changes with alter messages
// TODO: freeze loaded sections during update with aria-busy
// TODO: investigate proper implementation of aria-live region for updated sections
// TODO: keep track that focus stays on the same place during PLP update
// TODO: loadmore in session storage (back button from PDP issue)
import { scrollWindowTo } from 'widgets/toolbox/scroll';
import { getContentByUrl } from 'widgets/toolbox/ajax';
import {
    appendParamToURL
} from 'widgets/toolbox/util';

/**
 * @typedef {ReturnType<typeof import('widgets/global/Tabs').default>} Tabs
 * @typedef {ReturnType<typeof import('widgets/product/ProductTile').default>} ProductTile
 * @typedef {ReturnType<typeof import('widgets/forms/InputSelect').default>} InputSelect
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 * @typedef {InstanceType<typeof import('widgets/Widget').default>} widget
 */

/**
 * @description Base ProductListingMgr implementation
 * @param {Tabs} Tabs Base widget for extending
 * @returns {typeof ProductListingMgr} PLP Manager
 */
export default function (Tabs) {
    /**
     * @class ProductListingMgr
     * @augments Tabs
     * @classdesc Represents Product Listing Page with specific logic for
     *     updating product grid using product refinement, sorting and paging
     * @property {string} data-show-url Search show url
     * @property {string} data-show-ajax-url Search show AJAX url
     * @property {string} data-load-more-block Block with a button `Load more` for products
     * @property {string} data-load-more-prev Block with a button `Load previous` for products
     * @property {string} data-product-progress-indicator - reference element with search progress indicator for products search
     * @property {string} data-content-progress-indicator - reference element with search progress indicator for content search
     * @property {string} data-load-more-content-block - Block with a button `Load more` for content
     * @property {object} data-accessibility-alerts - Accessibility alerts messages for different user actions
     * @property {boolean} data-first-page - First page flag
     * Possible values are: `filtersapplied`, `filterremoved`, `sortingapplied`, `productlistupdated`, `addedtowishlist`, `previousstatereverted`
     * @example
     * // use this code to display widget
     * <div
     *     data-widget="productListingMgr"
     *     data-show-url="${pdict.productSearch.historyUrl}"
     *     data-show-ajax-url="${pdict.productSearch.ajaxUrl}"
     *     data-event-keydown="handleKeydown"
     *     data-auto-activation="true"
     *     data-accessibility-alerts='{
     *         "filtersapplied": "${Resource.msg('alert.filtersapplied', 'search', null)}",
     *         "filterremoved": "${Resource.msg('alert.filterremoved', 'search', null)}",
     *         "sortingapplied": "${Resource.msg('alert.sortingapplied', 'search', null)}",
     *         "productlistupdated": "${Resource.msg('alert.productlistupdated', 'search', null)}",
     *         "addedtowishlist": "${Resource.msg('alert.addedtowishlist', 'product', null)}",
     *         "previousstatereverted": "${Resource.msg('alert.previousstatereverted', 'search', null)}"
     *     }'
     * >
     *     .... product list contents
     * </div>
     */
    class ProductListingMgr extends Tabs {
        prefs() {
            return {
                classesActiveSection: 'm-expanded',
                classesBusy: 'm-busy',
                loadMoreBlock: 'loadMoreBlock',
                loadPrevBlock: 'loadPrevBlock',
                productProgressIndicator: 'productProgressIndicator',
                contentProgressIndicator: 'contentProgressIndicator',
                loadMoreContentBlock: 'loadMoreContentBlock',
                accessibilityAlerts: {},
                showAjaxUrl: '',
                showUrl: '',
                isScrollOnload: true,
                firstPage: true,
                ...super.prefs()
            };
        }

        /**
         * @description Init widget logic
         * @returns {void}
         */
        init() {
            super.init();
            this.initEvents();
            this.handleHistory(true);

            if (this.prefs().isScrollOnload && !this.prefs().firstPage) {
                scrollWindowTo(this.ref('grid').get(), true);
            }
        }

        /**
         * @param {string} url Update URL
         * @param {refElement} [ref] Reference element
         * @param {boolean} [handleHistory] Handle history flag
         * @returns {Promise} result
         */
        updateByUrl(url, ref, handleHistory) {
            this.busy();
            return getContentByUrl(url)
                .then(response => {
                    if (typeof response === 'string') {
                        this.ref('productGrid').empty();
                        this.render(undefined, undefined, ref || this.ref('self'), response)
                            .then(() => {
                                if (handleHistory) {
                                    this.handleHistory();
                                    scrollWindowTo(this.ref('grid').get(), true);
                                }
                            });
                    }
                }).catch(()=> {
                    this.handleNoResult();
                })
                .finally(() => {
                    this.unbusy();
                });
        }

        /**
         * @description Handle History
         * @param {boolean} [isReplace] Replace flag
         * @param {object} [history] history object
         */
        handleHistory(isReplace = false, history) {
            const state = {
                ajaxUrl: history ? history.showAjaxUrl : this.prefs().showAjaxUrl
            };
            let historyURL = history ? history.showUrl : this.prefs().showUrl;

            if (document.location.hash) {
                historyURL += document.location.hash;
            }

            if (isReplace) {
                window.history.replaceState(state, '', historyURL);
            } else {
                window.history.pushState(state, '', historyURL);
            }
        }

        /**
         * @description Update View
         * @param {refElement|widget} button Target element
         * @returns {void}
         */
        updateView(button) {
            const updateURL = button.data('href');
            const refinementSelected = button.data('checked');
            const alertMessage = refinementSelected
                ? this.prefs().accessibilityAlerts.filterremoved
                : this.prefs().accessibilityAlerts.filtersapplied;

            this.updateByUrl(updateURL, undefined, true)
                .then(() => this.accessibilityAlert(alertMessage));
        }

        /**
         * @description Update Grid
         * @param {InstanceType<InputSelect>} select Target element
         * @returns {void}
         */
        updateGrid(select) {
            const selectedSorting = select.getSelectedOptions();
            if (selectedSorting) {
                const url = selectedSorting.data('url');
                this.updateByUrl(url, undefined, true)
                    .then(() => this.accessibilityAlert(this.prefs().accessibilityAlerts.sortingapplied));
            }
        }

        /**
         * @description Load more products
         * @param {refElement} button Target element
         * @returns {void}
         */
        loadMore(button) {
            const href = button.data('show-url');
            const history = {
                showUrl: button.attr('href'),
                showAjaxUrl: button.data('show-ajax-url')
            };
            let url = '';

            if (typeof href === 'string') {
                url = href;
            }

            this.ref('loadMoreBlock').remove();
            this.ref('productProgressIndicator').remove();
            this.loadChunk(url, response => {
                if (typeof response === 'string') {
                    this.ref('productGrid').append(response);
                    this.accessibilityAlert(this.prefs().accessibilityAlerts.productlistupdated);
                }
            }, true, history);
        }

        /**
         * @description Load previous products
         * @param {refElement} button Target element
         * @returns {void}
         */
        loadPrev(button) {
            const href = button.data('show-url');
            const history = {
                showUrl: button.attr('href'),
                showAjaxUrl: button.data('show-ajax-url')
            };
            let url = '';

            if (typeof href === 'string') {
                url = href;
            }

            this.ref('loadPrevBlock').remove();
            this.loadChunk(url, response => {
                if (typeof response === 'string') {
                    this.ref('productGrid').prepend(response);
                    this.accessibilityAlert(this.prefs().accessibilityAlerts.productlistupdated);
                }
            }, true, history);
        }

        /**
         * @description Load more content
         * @param {refElement} button Target element
         * @returns {void}
         */
        loadMoreContent(button) {
            const href = button.attr('href');
            let url = '';

            if (typeof href === 'string') {
                url = href;
            }

            this.ref('loadMoreContentBlock').remove();
            this.ref('contentProgressIndicator').remove();
            this.loadChunk(url, response => {
                if (typeof response === 'string') {
                    this.ref('contentGrid').append(response);
                    this.accessibilityAlert(this.prefs().accessibilityAlerts.productlistupdated);
                }
            }, false);
        }

        /**
         * @description Loads chunk of data and executes callback. Data might be either products or content
         * @param {string} url load chunk url
         * @param {(arg: Response) => void} successCb success callback to be called after chunk was loaded with response as an argument
         * @param {boolean} handleHistory do we need to handle history change after chunk loaded
         * @param {object} [history] history object
         */
        loadChunk(url, successCb, handleHistory, history) {
            if (!url) {
                console.error('Somithing wrong with target URL');
            } else {
                this.busy();
                getContentByUrl(
                    appendParamToURL(url, 'selectedUrl', url)
                ).then(response => {
                    successCb(response);

                    if (handleHistory) {
                        this.handleHistory(true, history);
                    }
                }).finally(() => {
                    this.unbusy();
                });
            }
        }

        /**
         * @description Show appropriate Global Alert for page changes
         * @param {string} message - alert message
         * @returns {void}
         */
        accessibilityAlert(message) {
            // We need to show alerts with delay to not overlap with other big changes on the page
            setTimeout(() => this.eventBus().emit('alert.show', { accessibilityAlert: message }), 400);
        }

        /**
         * @description Busy
         * @returns {void}
         */
        busy() {
            this.ref('productGrid').addClass(this.prefs().classesBusy).attr('aria-busy', 'true');
            this.ref('contentGrid').addClass(this.prefs().classesBusy).attr('aria-busy', 'true');
        }

        /**
         * @description Unbusy
         * @returns {void}
         */
        unbusy() {
            this.ref('productGrid').removeClass(this.prefs().classesBusy).attr('aria-busy', false);
            this.ref('contentGrid').removeClass(this.prefs().classesBusy).attr('aria-busy', false);
        }

        /**
         * @description Skip refinements and focus first element in product grid on "Enter" keydown event
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         */
        skipRefinements(_, event) {
            let preventEventActions = false;
            let focused = false;

            switch (event.keyCode) {
                case keyCode.RETURN:
                    scrollWindowTo(this.ref('productGrid').get(), true);
                    this.getById('firstTile', elemet => {
                        focused = true;
                        elemet.focus();
                    });
                    if (!focused && this.items) {
                        const ProductTile = /** @type {ProductTile} */(this.getConstructor('productTile'));

                        this.items.some(item => {
                            if (item instanceof ProductTile && item.prefs().gridItem) {
                                item.focus();

                                return true;
                            }

                            return false;
                        });
                    }

                    preventEventActions = true;
                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        /**
         * @description Init Events
         * @returns {void}
         */
        initEvents() {
            this.ev('popstate', (_target, event) => {
                if (event instanceof PopStateEvent && event.state) {
                    const hashChangedOnly = get(event, 'state.hashChangedOnly');
                    if (hashChangedOnly) {
                        this.handleUrlChange(false);
                        return;
                    }

                    let updateUrl = get(event, 'state.ajaxUrl') || window.location.href;
                    updateUrl = appendParamToURL(updateUrl, 'history', 'true');

                    this.updateByUrl(updateUrl, undefined, false)
                        .then(() => {
                            this.handleUrlChange(false);
                            const accessibilityAlert = this.prefs().accessibilityAlerts.previousstatereverted;
                            this.eventBus().emit('alert.show', {
                                accessibilityAlert
                            });
                        });
                }
            }, window);
        }

        /**
         * @description No Result Combination handler
         * @returns {void}
         */
        handleNoResult() {
            this.getById('noResultPopup', popup => popup.showModal());
        }
    }

    return ProductListingMgr;
}
