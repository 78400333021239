import {
    appendParamToURL,
    getUrlParams
} from 'widgets/toolbox/util';

const keyCode = Object.freeze({
    ESC: 27,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    UP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39
});

/**
 * @typedef {ReturnType <typeof import('widgets/global/ListAccessibility').default>} ListAccessibility
 * @typedef {ReturnType <typeof import('widgets/global/Refinement').default>} Refinement
 * @typedef {InstanceType <Refinement>} refinement
 */

/**
 * @description Base RefinementMenuItem implementation
 * @param {ListAccessibility} ListAccessibility Base widget for extending
 * @returns {typeof RefinementMenuItem} RefinementMenuItem widget
 */
export default function (ListAccessibility) {
    /**
     * @class RefinementMenuItem
     * @augments ListAccessibility
     * @classdesc Represents Refinement Menu Item with specific logic for submenus, keyboard navigation.
     * Represents RefinementMenuItem component with next features:
     * 1. Support keyboard navigation for accessibility
     * 2. Show control button according to selected refinements
     * 3. Build update url for refinement panel
     *
     * Should contain {@link Refinement} widget inside:
     * @property {string} data-widget - Widget name `refinementMenuItem`
     * @property {string} data-event-keydown - Event listener for `handleKeydown` method
     * @property {boolean} [data-prevent-show-submenu=false] - prevent showing submenu
     * @property {boolean} [data-prevent-refinement-selected=false] - prevent showing submenu
     * @property {string} data-refinebar-url - refine bar url
     * @property {string} data-widget-event-previous - Previous panel handler
     * @property {string} data-update-url - update url
     * @category widgets
     * @subcategory search
     * @example
     * <div
     *     data-widget="refinementMenuItem"
     *     data-forward-to-parent="updateView:closePanel"
     *     data-refinement-selected="{{${'#'}selectedFiltersText}}true{{/selectedFiltersText}}{{^selectedFiltersText}}false{{/selectedFiltersText}}"
     *     data-title="{{displayName}}"
     * >
     *     <div
     *         class="b-slide_panel-item"
     *         role="menuitem"
     *         tabindex="0"
     *         aria-haspopup="true"
     *         aria-expanded="false"
     *         data-ref="itemLink"
     *         data-event-click.prevent="handleClick"
     *         data-tau="refinements_button"
     *     >
     *     <div class="b-slide_panel-link">
     *         <span class="b-slide_panel-name">
     *             {{displayName}}
     *         </span>
     *
     *         <div
     *             class="b-slide_panel-applied_filters"
     *             data-tau="applied_filter_value"
     *         >
     *             {{${'#'}isCategoryRefinement}}
     *                 {{${'#'}category}}
     *                     {{category.name}}
     *                 {{/category}}
     *             {{/isCategoryRefinement}}
     *             {{^isCategoryRefinement}}
     *                 {{selectedFiltersText}}
     *             {{/isCategoryRefinement}}
     *         </div>
     *     </div>
     *     <span class="b-slide_panel-item_icon">
     *         <isinclude template="/common/icons/standalone/arrowForwardSmall" />
     *     </span>
     * </div>
     */
    class RefinementMenuItem extends ListAccessibility {
        /**
         * @description Preferences
         * @returns {object} Preferences object
         */
        prefs() {
            return {
                preventShowSubmenu: false,
                refinementSelected: false,
                submenu: 'submenu',
                updateUrl: '',
                currentUrl: '',
                refinebarUrl: '',
                title: '',
                refinementId: '',
                ...super.prefs()
            };
        }

        /**
         * @description Has submenu
         * @returns {boolean} Return true if submenu exist
         */
        hasSubmenu() {
            return this.has(this.prefs().submenu) && !this.prefs().preventShowSubmenu;
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            this.defineItems();
            this.isSubmenuOpen = false;
        }

        /**
         * @description Initialize widget logic
         * @returns {Array<refinement>} Array of refinement widgets
         */
        getRefinements() {
            const Refinement = /** @type {Refinement} */(this.getConstructor('refinement'));

            let refinement = [];

            if (this.items) {
                refinement = this.items.filter((item) => {
                    if (item instanceof Refinement) {
                        return item.isAttributeRefinement();
                    }

                    return false;
                });
            }

            return refinement;
        }

        /**
         * @description Has Checked Refinements
         * @returns {boolean} Has Checked Refinements Flag
         */
        hasCheckedRefinements() {
            return this.getRefinements().some((refinement) => refinement.selected);
        }

        /**
         * @description Uncheck Refinements
         * @returns {void}
         */
        uncheckRefinements() {
            this.getRefinements().forEach((refinement) => {
                refinement.uncheck();
            });
        }

        /**
         * @description Clear Refinements
         * @returns {void}
         */
        clearRefinements() {
            this.uncheckRefinements();
            this.hideRefinementControls(true);
            this.defineItems();
            this.setFocusToFirstItem();
        }

        /**
         * @description Mark Submenu Opened
         * @returns {void}
         */
        markSubmenuOpened() {
            this.isSubmenuOpen = true;
            this.ref('self').attr('aria-expanded', 'true');
        }

        /**
         * @description Has opened submenu
         * @returns {boolean|undefined} Return true if submenu opened
         */
        hasOpenedSubmenu() {
            return this.isSubmenuOpen;
        }

        /**
         * @description Open next menu level if exist
         * @returns {void}
         */
        openMenu() {
            if (this.hasSubmenu()) {
                const itemName = this.prefs().title;
                let submenuHTML;

                this.has(this.prefs().submenu, (submenu) => {
                    const submenuElement = submenu.get();

                    if (submenuElement) {
                        submenuHTML = submenuElement.innerHTML;
                    }
                });
                this.eventBus().emit('refinement.panel.next.level', {
                    panelName: itemName,
                    refinementId: this.prefs().refinementId,
                    htmlMarkup: submenuHTML,
                    showRefinementControls: this.prefs().refinementSelected
                });
            }
        }

        /**
         * @description Show Refinement Controls
         * @param {boolean} [showBackButton=false] - Is back button should be shown
         * @returns {void}
         */
        showRefinementControls(showBackButton = false) {
            this.getById('clearBtn', (button) => button.show());
            this.getById('applyBtn', (button) => button.toggle(!showBackButton));
            this.getById('backBtn', (button) => button.toggle(showBackButton));
        }

        /**
         * @description Hide Refinement Controls
         * @param {boolean} [showApply] Show Apply button flag
         * @returns {void}
         */
        hideRefinementControls(showApply) {
            this.getById('clearBtn', (button) => button.hide());

            if (showApply) {
                this.getById('applyBtn', (button) => button.show());
                this.getById('backBtn', (button) => button.hide());
            } else {
                this.getById('applyBtn', (button) => button.hide());
                this.getById('backBtn', (button) => button.show());
            }
        }

        /**
         * @description Set Submenu Title
         * @param {string} submenuTitle Submenu Title
         * @returns {void}
         */
        setSubmenuTitle(submenuTitle) {
            this.has('title', (submenu) => {
                submenu.setText(submenuTitle);
            });
            this.ref('self').attr('aria-label', submenuTitle);
        }

        /**
         * @description Set Submenu id attribute to reference to it from aria-owns
         * @param {string} refinementId refinement id
         * @returns {void}
         */
        setSubmenuId(refinementId) {
            this.ref('self').attr('id', 'submenu-' + refinementId);
        }

        /**
         * @description Set Submenu HTML
         * @param {string} submenuHTML Submenu HTML
         * @returns {void}
         */
        setSubmenuHTML(submenuHTML) {
            this.has(this.prefs().submenu, (submenu) => {
                const submenuElement = submenu.get();

                if (submenuElement) {
                    submenuElement.innerHTML = submenuHTML;
                }
            });
        }

        /**
         * @description Click Event handler
         * @param {HTMLElement} _ Source of keydown event
         * @param {Event} event Event object
         */
        handleClick(_, event) {
            if (this.hasSubmenu()) {
                event.preventDefault();
                this.openMenu();
            }
        }

        /**
         * @description Close Submenu
         * @returns {void}
         */
        closeSubmenu() {
            this.eventBus().emit('refinement.panel.previous.level');
            this.ref('self').attr('aria-expanded', 'false');
            this.isSubmenuOpen = false;
        }

        /**
         * @description Parse Url Refinement Parameter
         * @returns {void}
         */
        parseUrlRefinementParameter() {
            /**
             * @type {object}
             */
            const paramMap = getUrlParams(this.prefs().updateUrl || this.prefs().currentUrl);

            this.refinementParameterMap = {};

            Object.keys(paramMap).forEach((paramKey) => {
                if (this.refinementParameterMap) {
                    if (paramKey.includes('prefn')) {
                        this.refinementParameterMap[paramMap[paramKey]] = paramMap[paramKey.replace('prefn', 'prefv')].split('|');
                    } else if (!paramKey.includes('prefv')) {
                        this.refinementParameterMap[paramKey] = paramMap[paramKey];
                    }
                }
            });
        }

        /**
         * @description Update Refinement Controls
         * @returns {void}
         */
        updateRefinementControls() {
            if (this.hasCheckedRefinements()) {
                this.showRefinementControls();
            } else {
                this.hideRefinementControls(true);
            }

            this.defineItems(this.currentItem);

            this.setFocusToCurrentItem();
        }

        /**
         * @description Apply change
         */
        applyChange() {
            const values = [];
            let url = this.prefs().refinebarUrl;
            let counter = 1;
            let type;
            let min;
            let max;

            this.getRefinements().forEach((refinement) => {
                type = refinement.attrId;

                if (refinement.selected) {
                    if (type === 'price') {
                        min = refinement.min;
                        max = refinement.max;
                    } else {
                        values.push(refinement.value);
                    }
                }
            });

            if (this.refinementParameterMap) {
                if (type === 'price') {
                    if (max) {
                        this.refinementParameterMap.pmin = min;
                        this.refinementParameterMap.pmax = max;
                    } else {
                        delete this.refinementParameterMap.pmin;
                        delete this.refinementParameterMap.pmax;
                    }
                } else if (values.length) {
                    this.refinementParameterMap[type] = values;
                } else {
                    delete this.refinementParameterMap[type];
                }

                Object.keys(this.refinementParameterMap).forEach((key) => {
                    if (this.refinementParameterMap && !['start', 'sz'].includes(key)) {
                        if (Array.isArray(this.refinementParameterMap[key])) {
                            url = appendParamToURL(url, 'prefn' + counter, key);
                            url = appendParamToURL(url, 'prefv' + counter, this.refinementParameterMap[key].join('|'));
                            counter++;
                        } else {
                            url = appendParamToURL(url, key, this.refinementParameterMap[key]);
                        }
                    }
                });

                this.eventBus().emit('refinement.panel.update', { url });
            }
        }

        /**
         * @description Keydown Event handler
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            if (!this.hasOpenedSubmenu()) {
                return;
            }

            switch (event.keyCode) {
                case keyCode.LEFT:
                    this.closeSubmenu();
                    preventEventActions = true;

                    break;

                case keyCode.PAGEUP:
                case keyCode.HOME:
                    this.setFocusToFirstItem();
                    preventEventActions = true;

                    break;

                case keyCode.PAGEDOWN:
                case keyCode.END:
                    this.setFocusToLastItem();
                    preventEventActions = true;

                    break;

                case keyCode.UP:
                    this.setFocusToPreviousItem();
                    preventEventActions = true;

                    break;

                case keyCode.DOWN:
                    this.setFocusToNextItem();
                    preventEventActions = true;

                    break;

                case keyCode.RIGHT:
                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return RefinementMenuItem;
}
